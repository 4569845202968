import {Tooltip} from 'bootstrap';

import {Words} from './blocks/words';
import {BackToTop} from './blocks/back-to-top';
import {PhoneMask} from './masks/phone';
import {LettersMask} from './masks/letters';

const wrap = () => {
    Words();
    BackToTop();
};

window.SLAVDEV_HELPERS = {
    bootstrap: {
        Tooltip
    },
    inputMasks: {
        PhoneMask,
        LettersMask,
    },
};

(() => {
    const app = {
        wrap
    };

    document.addEventListener(
        'DOMContentLoaded',
        () => {
            for (const key in app) {
                app[key]();
            }

            window.SLAVDEV_HELPERS.inputMasks.PhoneMask.maskAllPhoneFields();
            window.SLAVDEV_HELPERS.inputMasks.LettersMask.maskAllLettersFields();

            const removeAfterDownloadList = document.querySelectorAll('.js-remove-after-download');
            removeAfterDownloadList.forEach(item => {
                item.remove();
            })

            const listTooltip = document.querySelectorAll('[data-bs-toggle="tooltip"]');
            listTooltip.forEach(item => {
                return new window.SLAVDEV_HELPERS.bootstrap.Tooltip(item)
            })


            const forms = document.querySelectorAll('.needs-validation')
            forms.forEach(form => {
                form.addEventListener('submit', function (event) {
                    event.preventDefault()

                    if (!form.checkValidity()) {
                        event.stopPropagation()
                    }

                    form.classList.add('was-validated')

                    if (form.checkValidity()) {
                        const successMessage = this.querySelector('.js-successMessage');
                        const errorMessage = this.querySelector('.js-errorMessage');

                        successMessage.style.display = 'none';
                        errorMessage.style.display = 'none';

                        const formData = new FormData(form);
                        const options = {
                            method: 'POST', // Метод запроса
                            body: formData // Данные формы
                        };
                        fetch(form.action, options)
                            .then(response => {
                                if (!response.ok) {
                                    throw new Error('Network response was not ok');
                                }
                                return response.text(); // Возвращаем текстовый ответ от сервера
                            })
                            .then(data => {
                                const jsonObject = JSON.parse(data);
                                successMessage.innerHTML = jsonObject.message;
                                successMessage.style.display = 'block';
                                form.reset();
                                form.classList.remove('was-validated');
                            })
                            .catch(error => {
                                errorMessage.style.display = 'block';
                            });
                    }
                }, false)
            })
        },
        false
    );
})();

