"use strict";

import InputMask from "inputmask";

export const PhoneMask = {
  maskAllPhoneFields: () => {
    const phoneFields = document.querySelectorAll('[data-mask-phone]');

    phoneFields.forEach( phoneField => {
      PhoneMask.setPhoneMaskForField( phoneField )
    })
  },
  setPhoneMaskForField: field => {
    const mask = new InputMask("+7 999-9999999", {
      placeholder: "+7 ___-_______",
      onBeforeMask: function (value, opts) {
        let processedValue = value;
        if (processedValue.indexOf('+') !== 0) {
          if (processedValue.length === 11 && processedValue.indexOf('7') !== 0) {
            processedValue = '+7';
          } else {
            processedValue = '+' + processedValue;
          }
        }
        return processedValue;
      },
      oncomplete: function () {
      }
    });
    mask.mask(field);
  }
}