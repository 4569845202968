export function Words() {
    const lettersList = document.querySelectorAll("[data-type-letter]");

    if (lettersList.length) {
        for (let i = 0; i < lettersList.length; i++) {
            let letter = lettersList[i];
            letter.addEventListener('input', function (e) {
                e.preventDefault();

                if (this.value.length) {
                    if (lettersList[i + 1]) {
                        lettersList[i + 1].focus();
                    }
                }
            });
        }
    }

    const lettersRefreshList = document.querySelectorAll("[data-type-letters-refresh]");

    if (lettersRefreshList.length) {
        for (let i = 0; i < lettersRefreshList.length; i++) {
            let letterRefresh = lettersRefreshList[i];
            letterRefresh.addEventListener('click', function (e) {
                e.preventDefault();

                let letterList = document.querySelectorAll('.' + this.id);
                letterList.forEach(letterField => {
                    letterField.value = '';
                })
            });
        }
    }

    let formsList = document.querySelectorAll("[data-form-letters]");

    formsList.forEach(form => {
        form.addEventListener('submit', function (event) {
            // Предотвращаем стандартное поведение формы, чтобы страница не перезагружалась
            event.preventDefault();

            const formData = new FormData(form);
            const options = {
                method: 'POST', // Метод запроса
                body: formData // Данные формы
            };

            const wordsList = document.querySelector(".js-words-list");
            const wordsError = document.querySelector(".js-words-list-error");
            // Отправляем запрос с использованием fetch
            fetch(form.action, options)
                .then(response => {
                    // Проверяем, был ли запрос успешным (HTTP статус в диапазоне 200-299)
                    if (!response.ok) {
                        wordsList.innerHTML = '';
                        wordsError.innerHTML = 'Произошла ошибка при отправке формы';
                        //throw new Error('Произошла ошибка при отправке формы');
                    }
                    return response.text(); // Возвращаем текстовый ответ от сервера
                })
                .then(data => {
                    // Обрабатываем ответ от сервера
                    wordsList.innerHTML = data;
                    wordsError.innerHTML = '';
                })
                .catch(error => {
                    // Обрабатываем возможные ошибки
                    wordsList.innerHTML = '';
                    wordsError.innerHTML = 'Произошла ошибка: ' + error;
                });
        });
    });
}

