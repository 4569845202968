"use strict";

import InputMask from "inputmask";

export const LettersMask = {
    maskAllLettersFields: () => {
        const lettersFields = document.querySelectorAll('[data-mask-letters]');

        lettersFields.forEach(letterField => {
            LettersMask.setLettersMaskForField(letterField)
        })
    },
    setLettersMaskForField: field => {
        const mask = new InputMask({ regex: "[а-яА-Я]*" });
        mask.mask(field);
    }
}